import React from 'react'
import pricingImage from '../../images/pricing-image.jpg'
import styled from 'styled-components'
import { Container, SectionHeading, TextArea, DiagonalBackground } from '../StyledComponents'
import { StaticImage } from 'gatsby-plugin-image'
export default function Pricing() {

    return (
        <Container
            style={{
                position: 'relative',
                zIndex: '0',
                color: '#f9f9f9'
            }}
            background="radial-gradient(
            farthest-side at top right,
            transparent, 
            #161445
            ),
            radial-gradient(
            farthest-corner at bottom left,
            #fd98df,
            rgba(105, 50, 200, 1) 
            )"
        >
            <PricingContainer className="row">
                <TextArea className="pricing-text col-lg-6">
                    <SectionHeading>
                        <h3>Pricing</h3>
                        <h1>No fixed monthly fee</h1>
                        <small>*subscriptions available in the near future</small>
                        {/* <h3>Until 19th May 2021</h3> */}
                    </SectionHeading>
                    <strong>No up front costs</strong>
                    <p>
                        5% (minimum £1) charged to the customer
                        <br />
                        <small>* or you can take the charge, your choice</small>
                    </p>

                    <p>1.4% + 20p payment fee charged to you</p>
                    <br />
                    <strong>Includes:</strong>
                    <p>Unlimited Staff & Services</p>
                    <p>Unlimited Email Notifications</p>
                    <p>Unlimited Bookings, & unlimited reschedules</p>
                    {/* <p>Admin Page</p> */}
                    <br />
                </TextArea>

                <PricingImage className="pricing-image col-lg-6">
                    <StaticImage className="img" src="../../images/pricing-image.jpg" alt="" />
                </PricingImage>

            </PricingContainer>
        </Container >
    )
}


const PricingContainer = styled.div`
    padding: 1em;
`


const PricingImage = styled.div`
    height: 50vh;
    max-height: 600px;
    position: relative;
    z-index: 14 !important;
    .img {
        border-radius: ${({ theme }) => theme.borderRadius};
        height: 100%;
        width: 100%;
        object-fit: cover;
        
    }
`