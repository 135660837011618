import React from 'react'
import { Container, SectionHeading, TextArea, DiagonalBackground } from '../StyledComponents'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import themeImage from '../../images/Group-152.png'
export default function Theming() {

    return (
        <Container
            style={{
                color: 'whitesmoke',
            }}
            background='#161445'
        >
            <TextArea>
                <SectionHeading>
                    <h3>Theming</h3>
                    <h1>Your way</h1>
                </SectionHeading>
                <p>It’s your shop, build it your way to reflect your brand.</p>
            </TextArea>
            <ThemeImage>
                <StaticImage src="../../images/Group-152.png" />
            </ThemeImage>
        </Container>
    )
}

const ThemeImage = styled.div`
    // height: 50vh;
    max-height: 600px;
    position: relative;
    @media(min-width: 900px){
        border-radius: ${({ theme }) => theme.borderRadius};
    }
    img {
        width: 100%; 
        // max-height: 600px;
        padding: 0 1em;
        object-fit: contain;
        @media(min-width: 900px){
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
`