import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Input, PrimaryBtn, RightChevron, Message, Modal, Group, Checkbox } from 'zyppd-components'
import styled from 'styled-components'

export default function CTA() {

    return (<>
        <CallToActionBlock>
            <h3>Get zyppd.in</h3>
            <a href="https://admin.zyppd.in">
                <PrimaryBtn
                    fullWidth={true}
                >
                    Sign Up
            </PrimaryBtn>
            </a>
        </CallToActionBlock>
    </>
    )
}


const CallToActionBlock = styled.div`
    background: rgba(35,35,35,0.35);
    border-radius: ${({ theme }) => theme.borderRadius};
    box-shadow: ${({ theme }) => theme.shadow};
    padding: 1em;
    padding-right: 2em;
    margin: 2em -1em;
    h3{
        margin-bottom: .5em;
    }
    p {
        margin: 1em 0 0 0;
    }
    div {
        display: flex; 
        div {
            margin: 0 1em 0 0;
        }
    }
`

