import styled from 'styled-components'
import React, { useEffect, useState } from 'react'

import { useWindowSize, RightArrow } from 'zyppd-components'
export const ContainerStyles = styled.div`
    position: relative;
    min-height: 80vh;
    z-index: 2;
    .container{
        margin-bottom: 7em;
        max-width: 900px; 
        margin: 0 auto;
        padding: 3em 0;
    }
    .ellipses{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%; 
        overflow: hidden;
    }
`


export const Container = ({ children, background, style }, props) => {

    const windowSize = useWindowSize()
    const [ellipses, setEllipses] = useState()


    return (
        <ContainerStyles {...props} style={style}>
            {background &&
                <DiagonalBackground
                    background={background}
                />
            }

            <div className="container">
                {children}
            </div>
        </ContainerStyles>
    )
}

const Shape = styled.div`
    background: rgba(235,235,235,0.04);
    box-shadow: 0 1px 1px rgba(0,0,0,0.01), 
        0 2px 2px rgba(0,0,0,0.01), 
        0 4px 4px rgba(0,0,0,0.01), 
        0 8px 8px rgba(0,0,0,0.01),
        0 16px 16px rgba(0,0,0,0.01);
    display: inline-block;
    border-radius: 50%;
    position: absolute; 
    top: ${({ y }) => {
        return `calc(${y}px)`
    }};
    left: ${({ x }) => `calc(${x}px)`};
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
`

// export const SectionHeadingStyles = styled.div`
//     margin-bottom: 1em;
//     @media(max-width: 900px){
//         h3:first-of-type{
//             margin-left: 1.5em;
//         }
//         svg {
//             transform: translate(50%, 0);
//         }
//     }

//     svg{
//         position: absolute;
//         @media(min-width: 900px){
//             transform: translate(-50%, 0);
//         }
//         top: 0;
//         left: 0;
//     }
// `


export const SectionHeading = ({ children, style, className }) => {
    return (
        <SectionHeadingStyles
            style={style}
            className={className}
        >
            <RightArrow />
            {children}
        </SectionHeadingStyles>
    )
}


export const SectionHeadingStyles = styled.div`
    margin-bottom: 1em;
    position: relative;
    h3:first-of-type{
        margin-left: 1.25em;
    }
    svg{
        position: absolute;
        top: 0;
        left: 0;
    }
`
export const TextArea = styled.div`
    padding: 0 1em;
    margin-bottom: 1em;
    position: relative;
`

export const DiagonalBackground = styled.div`
    position: absolute;
    top: -100px;
    left: 0;
    right: 0;
    bottom: -50px;
    padding-bottom: 50px;
    background: ${({ background }) => background ? background : '#161445'};
    transform: skewY(-5deg);
    z-index: -2;
    @media(min-width: 900px){
        border: 1em solid whitesmoke;
    }
`