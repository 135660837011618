import React from 'react'
import styled from 'styled-components'
import { Container, TextArea, SectionHeading, DiagonalBackground } from '../StyledComponents'
import CTA from '../CTA'
export default function Features() {

    return (
        <Container
            style={{
                paddingTop: '18vh',
                color: 'whitesmoke',
                zIndex: '0'
            }}
            background="radial-gradient(
                farthest-side at bottom left,
                transparent, 
                #161445
                ),
                radial-gradient(
                farthest-corner at top right,
                #fd98df,
                rgba(105, 50, 200, 1) 
                )"
        >
            <TextArea>
                <SectionHeading>
                    <h3>Features</h3>
                    <h1>Everything you need</h1>
                </SectionHeading>
            </TextArea>
            <FeaturesContainer>
                <Feature>
                    <SectionHeading>
                        <h3>Services</h3>
                    </SectionHeading>
                    <p>Set individual services with individual prices.</p>
                </Feature>
                <Feature>
                    <SectionHeading>
                        <h3>Staff</h3>
                    </SectionHeading>
                    <p>Add all your staff members, choose which service each of them offers and allow your customers to choose.</p>
                </Feature>
                <Feature>
                    <SectionHeading>
                        <h3>Opening Days and Times</h3>
                    </SectionHeading>
                    <p>Set different opening and closing times for each day.</p>
                </Feature>
            </FeaturesContainer>

            <div style={{
                padding: '2em'
            }}>
                <CTA />
            </div>


        </Container>
    )
}

const FeaturesContainer = styled.div`
    padding: 0 1em 1em 1em;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
   
`
const Feature = styled.div`
        flex: 1 1 250px;
        padding: 1em; 
        border-radius: ${({ theme }) => theme.borderRadius};
        background: rgba(0,0,0,.3);
`

const Steps = styled.div`
    padding: 4em 1em 0 1em;
    ol {
        counter-reset: my-awesome-counter;
        list-style: none;
        li {
            counter-increment: my-awesome-counter;
            margin: 1em 0;
            font-size: 1.2em;
            &::before {
                content: counter(my-awesome-counter) ". ";
                font-weight: bold;
                font-size: 3rem;
              }
        }
    }
`

