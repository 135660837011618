import React from 'react'
import styled from 'styled-components'
import { Container, TextArea, SectionHeading, DiagonalBackground } from '../StyledComponents'
import CTA from '../CTA'
export default function Features() {

    return (
        <Container
            style={{
                padding: '8vh 0 8vh 0',
                marginTop: '10vh',
                zIndex: '0',
                minHeight: 'auto',
                color: 'whitesmoke'
            }}
            background='linear-gradient(to top left, #25c3f2 0%, #43e896 100%)'
        >
            <FormContainer>
                <TextArea>
                    <h1>Any questions?</h1>
                    <a href="mailto:support@zyppd.in"
                    >
                        <h3>
                            support@zyppd.in
                        </h3>
                    </a>
                </TextArea>
            </FormContainer>


        </Container >
    )
}

const FormContainer = styled.div`
    border-radius: 1em;
    text-align: center;
`
