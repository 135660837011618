import React, { useState } from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Container } from '../StyledComponents'
import CTA from '../CTA'
import styled from 'styled-components'

const LandingContainer = styled.section`
    min-height: 100vh; 
    padding: 2em;
    padding-top: 4em;
    @media(max-width: 1000px){
        flex-direction: column;
    }
    padding-top: 4em;
    h1{
        font-size: 3em;
        line-height: 3rem;
        @media(min-width: 600px){
            font-size: 5em;
            line-height: 5rem;
        }
    }
    p.para {
        max-width: 35ch;
    }
    
`

const Content = styled.div`
    flex: 1;
    position: relative;
    z-index: 1;
    color: whitesmoke;
   
    iframe{
        width: 100%; 
        height: 100%; 
        margin: 1em 0;
        @media(min-width: 600px){
            margin: 1em -3em; 
        }
        padding: 0; 
    }
    
`
export default function Landing() {

    return (
        <div className="zyppd-background">

            <Container>
                <LandingContainer className="row">
                    <Content className="col-lg-12">
                        <h2>zyppd.in</h2>
                        <h1>Take Bookings, Now.</h1>
                        <p className="para">Focus on what you do best and give your business the online booking platform it deserves with zyppd.in.</p>
                        <CTA />
                    </Content>
                </LandingContainer>
            </Container>
        </div>
    )
}