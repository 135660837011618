import React, { useEffect } from "react"
import { Link } from "gatsby"
import { ZyppdComponents } from 'zyppd-components'
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"
import banner from '../images/grad-banner.png'
import NewSection from '../components/MarketingPage/NewSection'
import Landing from '../components/MarketingPage/Landing'
import Pricing from '../components/MarketingPage/Pricing'
import Theming from '../components/MarketingPage/Theming'
import Features from '../components/MarketingPage/Features'
import Contact from '../components/MarketingPage/Contact'

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Landing />
      {/* <NewSection /> */}
      <Pricing />
      <Theming />
      <Features />
      <Contact />
    </Layout>
  )
}

export default IndexPage


const FormContainer = styled.div`
  height: 100vh;
  display: flex;
  padding-top: 0em; 
  place-items: center; 
  iframe { 
    height: calc(100% - 3em); 
    width: 100%;
    border: none; 
    color: red;
    background: ${({ theme }) => theme.textColor};
    padding-top: 4em;
    border-radius: ${({ theme }) => theme.borderRadius};
  }
`
